import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"

export const query = graphql`
  query($locale: [GRAPHCMS_Locale!]!, $id: ID) {
    gcms {
      opening(locales: $locale, where: { id: $id }) {
        id
        description
        title
        openingType
      }
    }
  }
`

const openingDetails = ({ t, data }) => {
  return (
    <Layout>
      <div className="font-futurastd_book text-center lg:grid lg:grid-rows-1 lg:justify-evenly lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-center mb-3 mx-3 lg:mx-7">
        <div className="text-center inline-block bg-white">
          <span className="text-2xl">&lt;</span>
          <span className="tracking-title-3 text-base ml-3 mr-2 uppercase">
            {data.gcms.opening.title}
          </span>
          <span className="text-2xl">&gt;</span>
        </div>
      </div>
      <div className="mx-3 lg:mx-7">
        <div className="grid grid-col-1 md:grid-cols-5 mx-7 gap-4">
          <div className="col-span-1">
            <p className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
              {data.gcms.opening.title}
            </p>
          </div>
          <p className="col-span-1 md:col-span-3 font-futurastd_book text-xs order-last md:order-none">
            {data.gcms.opening.description}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default openingDetails
